import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

// TODO: use react styled component

const formStyles = (theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      marginTop: theme.spacing(15),
      marginBottom: "30px",
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      fontWeight: 700,
    },
  })

export const SignInStyles = makeStyles((theme) => {
  const styles = formStyles(theme)

  return {
    ...styles,
    form: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      width: "100%",
    }
  }
})

export const SignUpStyles = makeStyles((theme) => {
  const styles = formStyles(theme)

  return {
    ...styles,
    form: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      width: "100%",
    },
    helperText: {
      whiteSpace: "pre-wrap",
    },
    linkTextHeader: {
      margin: "6px",
      fontSize: "0.9rem",
      color: "#000000",
    },
  }
})
