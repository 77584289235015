import React, { useContext, useEffect, useState } from "react"
import { AppData } from "../typings/apps"
import { useTranslation } from "react-i18next"
import { AppSummaryCard } from "./AppSummaryCard"
import { getRelatedApps } from "../api/apps"
import { makeStyles, Theme } from "@material-ui/core"
import { MediaQueryContext } from "../App"

/*
コンポーネントの説明：
  アプリ詳細画面に表示されたアプリと同じサービスをもつアプリを表示します。
CSS定義場所：
  コンポーネント固有CSSとして、このファイル内の最下部で定義。
*/
type Props = {
  appId: string
  errorHandler: (error: Error) => void
}

export default function RelatedApps({ appId, errorHandler }: Props) {
  const { t } = useTranslation()

  const [data, setData] = useState<{ relatedapps: AppData[] }>()

  useEffect(() => {
    getRelatedApps(appId)
      .then((data) => {
        setData(data)
      })
      .catch(errorHandler)
  }, [appId])

  const { isPC } = useContext(MediaQueryContext)
  const pcStyles = usePcRootStyles()
  const spStyles = useSpRootStyles()
  const styles = isPC ? pcStyles : spStyles

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {/* 同じサービスの関連アプリ */}
        {t("appDetail.relatedApps")}
      </div>
      <div className={styles.cards}>
        {data?.relatedapps ? (
          data.relatedapps.map((app) => (
            <AppSummaryCard key={app.id} appData={app} />
          ))
        ) : (
          <div id="noresults">{t("appDetail.listNoResults")}</div>
        )}
      </div>
    </div>
  )
}

// PCスタイル定義
const usePcRootStyles = makeStyles((_: Theme) => ({
  // ルート
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  title: {
    color: "#B53230",
    fontSize: "20px",
    fontFamily: "'Jost', sans-serif",
  },
  cards: {
    padding: "20px 0px",
    display: "grid",
    gap: "30px",
    gridTemplateColumns: "repeat(auto-fit, minmax(340px, 0.4fr))",
    justifyContent: "center",
    "& div[id=noresults]": {
      justifyItems: "center",
      textAlign: "center",
    },
  },
}))

// SPスタイル定義
const useSpRootStyles = makeStyles((_: Theme) => ({
  // ルート
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  title: {
    color: "#B53230",
    fontSize: "18px",
    fontFamily: "'Jost', sans-serif",
  },
  cards: {
    padding: "20px 0px",
    display: "grid",
    gap: "30px",
    gridTemplateColumns: "repeat(auto-fit, minmax(325px, 0.4fr))",
    justifyContent: "center",
    "& div[id=noResults]": {
      textAlign: "center",
    },
  },
}))
