import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { EmployerLayoutProps } from "../typings"
import SnackAlert from "./SnackAlert"
import { Pagination } from "@material-ui/lab"
import { AppSummaryCard } from "./AppSummaryCard"
import { getMyApps } from "../api/apps"
import { AppsData } from "../typings/apps"
import { MediaQueryContext } from "../App"

/*
コンポーネントの説明：
  ユーザーが購入済みのアプリを表示します。
*/
export const MyApps = (props: EmployerLayoutProps) => {
  // アプリ情報取得時のエラーハンドラーとアラート用のデータ
  const { alert, errorHandler, token } = props

  //
  const [myApps, setMyApps] = useState<AppsData>()

  // 国際化対応
  const { t } = useTranslation()

  // ページ初回読み込み時にマイアプリ情報取得
  useEffect(() => {
    updateResults()
  }, [])

  // ページ変更によるマイアプリ情報取得
  const onPageChanged = (_: ChangeEvent<unknown>, page: number) => {
    updateResults(page)
  }

  // 検索結果更新処理
  const updateResults = (page: number = 1) => {
    getMyApps(page, token)
      .then((data) => {
        setMyApps(data)
      })
      .catch(errorHandler)
  }

  // PC・SPで共通のスタイル
  const { isPC } = useContext(MediaQueryContext)
  const pcStyles = useStyles("20px", "340px", "40px")()
  const spStyles = useStyles("18px", "325px", "20px")()
  const styles = isPC ? pcStyles : spStyles

  // 検索結果の存在チェックヘルパー
  const isExistResults = myApps && myApps.apps.length > 0

  return (
    // PC・SPで共通のHTML構造
    isExistResults ? (
      <div className={styles.root}>
        {/* アプリタイトルとキーワード検索 */}
        <div className={styles.title}>
          <h3>{t("listApps.myAppsHeader")}</h3>
        </div>
        {/* マイアプリ要約カードを並べる */}
        <div className={styles.cards}>
          {isExistResults
            ? myApps?.apps.map((app) => (
                <AppSummaryCard key={app.id} appData={app} />
              ))
            : null}
        </div>
        {/* ページネーション */}
        {
          <div className={styles.pagination}>
            <Pagination
              count={myApps ? myApps.pageCount : 1}
              page={myApps ? myApps.page : 1}
              onChange={onPageChanged}
              color="primary"
            />
          </div>
        }
        <SnackAlert alert={alert} />
      </div>
    ) : null
  )
}

// PC・SPで共通のスタイル
const useStyles = (fontSize: string, minColmun: string, hPadding: string) =>
  makeStyles((_: Theme) => ({
    // ルート
    root: {
      position: "relative",
      padding: `40px ${hPadding}`,
      backgroundColor: "#f2f2f2",
    },
    // タイトル
    title: {
      display: "flex",
      alignItems: "center",
      borderBottom: "2px solid rgba(181, 50, 48, 0.5)",
      color: "#B53230",
      fontSize,
      whiteSpace: "nowrap",
    },
    // アプリ要約カード表示エリア
    cards: {
      padding: "20px 0px",
      display: "grid",
      gap: "30px",
      gridTemplateColumns: `repeat(auto-fit, minmax(${minColmun}, 0.4fr))`,
      justifyContent: "center",
    },
    // ページネーション
    pagination: {
      paddingTop: "20px",
      display: "flex",
      justifyContent: "center",
    },
  }))
