import React, { useState, useEffect, useContext } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import logo_saastainer from "../img/toppage/logo_saastainer.png"
import icon_question from "../img/toppage/icon_question.png"
import icon_twitter from "../img/toppage/icon_twitter.png"
import icon_open_drawer from "../img/toppage/icon_open_drawer.png"
import icon_close_drawer from "../img/toppage/icon_close_drawer.png"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { SearchByKeyword } from "./SearchByKeyword"
import AccountMenu from "./AccountMenu"
import { AuthContext, MediaQueryContext } from "../App"
import { MenuItemEntry } from "../typings"

/*
コンポーネントの説明：
  グローバルヘッダーコンポーネント。
CSS定義場所：
  コンポーネント固有CSSとして、このファイル内の最下部で定義。
*/

type Props = {
  menuItems: MenuItemEntry[]
}

function GlobalHeader(props: Props) {
  // コンテキストからログイン状態を取得
  const { isLogin } = useContext(AuthContext)

  // コンテキストからメディアクエリーに必要なリソースを取得
  const { isPC, pcWidth, spWidth } = useContext(MediaQueryContext)

  // propsからアカウント種別固有のメニューアイテムを取得
  const { menuItems } = props

  // 全画面更新を防ぐ目的で内部リンク遷移時のみ使用（外部リンクはhrefで遷移する）
  const history = useHistory()

  // スクロールイベントを購読。scrollYの値が０の場合はヘッダーの背景を透過することが目的
  const [scrollY, setScrollY] = useState<number>(0)
  const scrollPosChanged = () => setScrollY(window.scrollY)
  useEffect(() => {
    window.addEventListener("scroll", scrollPosChanged)
    // clean up
    return () => window.removeEventListener("scroll", scrollPosChanged)
  }, [])

  // トップページ(/apps)の場合はscrollYの値が0の時に背景を透過させるスタイルを適用する
  const location = useLocation()
  const getHeaderStyle = (styles: ClassNameMap<any>) => {
    const defaultStyle = styles.defaultHeader
    if (location.pathname === "/apps")
      return scrollY
        ? defaultStyle
        : `${styles.defaultHeader} ${styles.topPageHeader}`
    return defaultStyle
  }

  // 国際化対応
  type LngType = "ja" | "en"
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState<LngType>(() => {
    // 初期化時にローカルストレージから言語設定を読み込む
    const savedLang = localStorage.getItem('preferredLanguage')
    if (savedLang === 'ja' || savedLang === 'en') {
      i18n.changeLanguage(savedLang)
      return savedLang
    }
    return i18n.language as LngType
  })

  const changeLanguage = (lng: LngType) => {
    i18n.changeLanguage(lng)
    setLanguage(lng)
    // 言語設定をローカルストレージに保存
    localStorage.setItem('preferredLanguage', lng)
  }

  // PC固有のスタイル定義
  const usePCStyles = makeStyles((_: Theme) => ({
    // ルート
    root: {
      position: "fixed",
      zIndex: 10,
      width: "100%",
      top: 0,
    },
    // デフォルトのヘッダー
    defaultHeader: {
      display: "flex",
      justifyContent: "space-between",
      width: pcWidth,
      height: "76px",
      alignItems: "center",
      marginLeft: "auto",
      marginRight: "auto",
      background: "linear-gradient(135deg, #B53230 50%, #DD9695)",
    },
    // トップページの場合はScrollYの値が0の時にヘッダーを透過するスタイルをあてる
    topPageHeader: {
      background: "rgba(0, 0, 0, 0)",
    },
    // SaaStainerロゴ
    logo_saastainer: {
      width: "257px",
      height: "38px",
    },
    // ヘッダー内の左側に配置した要素群
    leftElementsInHeader: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "38%",
      paddingLeft: "20px",
      "& a": {
        paddingRight: "15px",
        color: "white",
        textDecoration: "none",
        filter: "drop-shadow(3px 3px 3px #4b2612)",
        display: "flex",
        alignItems: "center",
      },
    },
    // ヘッダー内の中央に配置した要素群
    centralElementsInHeader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "23%",
      "& a": {
        textDecoration: "none",
        filter: "drop-shadow(3px 3px 3px #a75629)",
        cursor: "pointer",
      },
    },
    // ヘッダー内の右側に配置した要素群
    rightElementsInHeader: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "38%",
      paddingRight: "20px",
      "& a": {
        paddingLeft: "15px",
        color: "white",
        textDecoration: "none",
        filter: "drop-shadow(3px 3px 3px #a75629)",
      },
    },
    // ヘッダー内の右側に配置した要素群(ログイン時の拡張)
    rightElementsInHeaderEx: {
      width: "45%",
    },
    // ボタン基本スタイル
    baseButton: {
      display: "block",
      width: "130px",
      height: "40px",
      padding: "11px",
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: "12px",
      fontWeight: "bold",
      textAlign: "center",
      letterSpacing: "0.1em",
      borderRadius: "100px",
      boxSizing: "border-box",
      textDecoration: "none",
      boxShadow: "0 2px 4px rgba(0,0,0,0.3)",
    },
    // 無料会員登録ボタン
    registrationButton: {
      color: "white",
      background: "linear-gradient(top left, #527CC5, #325BA1)",
      margin: "0px 5px",
      fontWeight: "normal",
    },
    // ログインボタン
    loginButton: {
      color: "#335ca2 !important",
      backgroundColor: "white",
      margin: "0px 5px",
    },
    // 使い方・ヘルプ
    help: {
      position: "relative",
      filter: "drop-shadow(3px 3px 3px #a75629)",
      "& a": {
        color: "white",
        textDecoration: "none",
        filter: "drop-shadow(3px 3px 3px #a75629)",
        marginLeft: "30px",
        whiteSpace: "nowrap",
      },
      "& img": {
        position: "absolute",
        width: "19px",
        height: "19px",
        left: "20px",
      },
    },
    // ツイッター
    twitter: {
      filter: "drop-shadow(3px 3px 3px #4b2612)",
      width: "28px",
      height: "28px",
    },
    // 言語切り替え
    i18n: {
      display: "flex",
      marginLeft: "25px",
      color: "white",
      "& button": {
        background: "rgba(255,0,0,0)",
        border: "0px",
        cursor: "pointer",
      },
    },
    // 選択されている言語切り替えボタンのスタイル
    selectedLanguage: {
      color: "white",
      filter: "drop-shadow(3px 3px 3px #4b2612)",
    },
    // 選択されていない言語切り替えボタンのスタイル
    unSelectedLanguage: {
      color: "rgba(255, 255, 255, 0.7)",
    },
  }))

  // SP固有のスタイル定義
  const useSPStyles = makeStyles((_: Theme) => ({
    // ルート
    root: {
      position: "fixed",
      zIndex: 10,
      width: spWidth,
      top: 0,
    },
    // デフォルトのヘッダー
    defaultHeader: {
      zIndex: 100,
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      height: "65px",
      alignItems: "center",
      marginLeft: "auto",
      marginRight: "auto",
      background: "linear-gradient(135deg, #B53230 50%, #DD9695)",
    },
    // トップページの場合はScrollYの値が0の時にヘッダーを透過するスタイルをあてる
    topPageHeader: {
      background: "rgba(0, 0, 0, 0)",
    },
    // ドロワーメニューが開かれているときのヘッダー
    openedDrawerHeader: {
      background: "linear-gradient(135deg, #B53230 50%, #DD9695)",
    },
    // SaaStainerロゴ
    logo_saastainer: {
      width: "200px",
      height: "32px",
    },
    // ヘッダー内の左側に配置した要素群
    leftElementsInHeader: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "50%",
      paddingLeft: "20px",
      "& a": {
        paddingRight: "15px",
        color: "white",
        textDecoration: "none",
        filter: "drop-shadow(3px 3px 3px #4b2612)",
      },
    },
    // ヘッダー内の右側に配置した要素群
    rightElementsInHeader: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "50%",
      paddingRight: "20px",
    },
    // ドロワーメニュー開くアイコン
    icon_open_drawer: {
      width: "28px",
      height: "18px",
      cursor: "pointer",
      marginLeft: "5px",
    },
    // ドロワーメニュー閉じるアイコン
    icon_close_drawer: {
      width: "29px",
      height: "29px",
      cursor: "pointer",
      marginLeft: "5px",
    },
    // ドロワーメニュー
    drawer: {
      position: "fixed",
      left: 0,
      top: "65px",
      width: "100%",
      height: "calc(100vh - 65px)",
      display: "flex",
      flexFlow: "column",
      justifyContent: "start",
      background: "linear-gradient(135deg, #B53230 50%, #DD9695)",
      transform: "translateX(-100%)",
      transition: "transform 0.3s ease-in-out",
      visibility: "hidden",
      overflowX: "hidden",
    },
    openDrawer: {
      transform: "translateX(0)",
      visibility: "visible",
    },
    upperElementsInDrawer: {
      display: "flex",
      flexFlow: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      padding: "20px",
      height: "100px",
      "& a": {
        color: "white",
        textDecoration: "none",
        filter: "drop-shadow(3px 3px 3px #4b2612)",
      },
    },
    middleElementsInDrawer: {
      display: "flex",
      flexFlow: "column",
      justifyContent: "space-between",
      alignItems: "center",
      width: "375px",
      padding: "20px 20px",
      maxHeight: "250px",
      "& a": {
        marginBottom: "20px",
      },
      marginLeft: "auto",
      marginRight: "auto",
    },
    lowerElementsInDrawer: {
      display: "flex",
      flexFlow: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      padding: "20px",
      height: "150px",
    },
    // ボタン基本スタイル
    baseButton: {
      display: "block",
      width: "100%",
      height: "40px",
      padding: "11px",
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: "12px",
      fontWeight: "bold",
      textAlign: "center",
      letterSpacing: "0.1em",
      borderRadius: "100px",
      boxSizing: "border-box",
      textDecoration: "none",
      boxShadow: "0 2px 4px rgba(0,0,0,0.3)",
      cursor: "pointer",
    },
    // 無料会員登録ボタン
    registrationButton: {
      color: "white",
      background: "linear-gradient(top left, #527CC5, #325BA1)",
      margin: "0px 5px",
      fontWeight: "normal",
    },
    // ログインボタン
    loginButton: {
      color: "#335ca2 !important",
      backgroundColor: "white",
      margin: "0px 5px",
    },
    // 使い方・ヘルプ
    help: {
      position: "relative",
      filter: "drop-shadow(3px 3px 3px #a75629)",
      "& a": {
        color: "white",
        textDecoration: "none",
        filter: "drop-shadow(3px 3px 3px #a75629)",
        marginLeft: "25px",
      },
      "& img": {
        position: "absolute",
        width: "19px",
        height: "19px",
      },
    },
    // ツイッター
    twitter: {
      display: "block",
      padding: "8px 0px",
      filter: "drop-shadow(3px 3px 3px #4b2612)",
      width: "28px",
      height: "28px",
    },
    // 言語切り替え
    i18n: {
      display: "flex",
      color: "white",
      "& button": {
        background: "rgba(255,0,0,0)",
        border: "0px",
        cursor: "pointer",
      },
    },
    // 選択されている言語切り替えボタンのスタイル
    selectedLanguage: {
      color: "white",
      filter: "drop-shadow(3px 3px 3px #4b2612)",
    },
    // 選択されていない言語切り替えボタンのスタイル
    unSelectedLanguage: {
      color: "rgba(255, 255, 255, 0.7)",
    },
  }))

  // PC固有のスタイルを読み込む
  const pcStyles = usePCStyles()

  // PCサイトヘッダー
  const pcHeader = (
    <div className={pcStyles.root}>
      <header className={getHeaderStyle(pcStyles)}>
        <div className={pcStyles.leftElementsInHeader}>
          <a href="/apps">
            {/* SaaStanerロゴ */}
            <img
              className={pcStyles.logo_saastainer}
              src={logo_saastainer}
              alt="SaaStainer"
            />
          </a>
          {/* 導入事例 */}
          <a
            href="https://www.strategit.jp/works/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("header.usecase")}
          </a>
          {/* 運営会社 */}
          <a
            href="https://www.strategit.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("header.company")}
          </a>
        </div>
        {/* 未認証時に表示 */}
        {isLogin ? null : (
          <div className={pcStyles.centralElementsInHeader}>
            <a
              className={`${pcStyles.baseButton} ${pcStyles.registrationButton}`}
              onClick={() => history.push("/signup")}
            >
              {/* 無料会員登録 */}
              {t("header.registration")}
            </a>
            <a
              className={`${pcStyles.baseButton} ${pcStyles.loginButton}`}
              onClick={() => history.push("/signin")}
            >
              {/* ログイン */}
              {t("header.login")}
            </a>
          </div>
        )}
        {/* 認証済みの場合はアカウントメニュー表示エリアを拡張するスタイルをあてる */}
        <div
          className={`${pcStyles.rightElementsInHeader} ${
            isLogin ? pcStyles.rightElementsInHeaderEx : undefined
          }`}
        >
          {/* キーワードで検索 */}
          <SearchByKeyword display={location.pathname === "/apps"} />
          <div className={pcStyles.help}>
            <img src={icon_question} />
            {/* 使い方・ヘルプ */}
            <a
              href="https://saastainer.zendesk.com/hc/ja"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("header.help")}
            </a>
          </div>
          <a
            className={pcStyles.twitter}
            href="https://twitter.com/SaaStainer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={icon_twitter} />
          </a>
          {/* 言語切り替え */}
          <div className={pcStyles.i18n}>
            <button
              className={
                language === "ja"
                  ? pcStyles.selectedLanguage
                  : pcStyles.unSelectedLanguage
              }
              onClick={() => changeLanguage("ja")}
            >
              JP
            </button>
            <p>|</p>
            <button
              className={
                language === "en"
                  ? pcStyles.selectedLanguage
                  : pcStyles.unSelectedLanguage
              }
              onClick={() => changeLanguage("en")}
            >
              EN
            </button>
          </div>
          {/* アカウントメニュー（ログイン時にのみ表示） */}
          {isLogin ? <AccountMenu menuItems={menuItems} /> : null}
        </div>
      </header>
    </div>
  )

  // SP固有のスタイルを読み込む
  const spStyles = useSPStyles()

  // ドロワーメニューの閉開状態
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false)

  // ドロワーメニューの閉開時の処理
  const toggleDrawerMenu = () => {
    setDrawerOpen((prev) => !prev)
  }
  // 区切り線
  const divider = <hr color="white" style={{ width: "100%", opacity: "0.3" }} />

  // SPサイトヘッダー
  const spHeader = (
    <>
      <div className={spStyles.root}>
        <header
          className={`${getHeaderStyle(spStyles)} ${
            isDrawerOpen ? spStyles.openedDrawerHeader : undefined
          }`}
        >
          <div className={spStyles.leftElementsInHeader}>
            <a href="/apps">
              {/* SaaStainerロゴ */}
              <img
                className={spStyles.logo_saastainer}
                src={logo_saastainer}
                alt="SaaStainer"
              />
            </a>
          </div>
          <div className={spStyles.rightElementsInHeader}>
            {/* アカウントメニュー（ログイン時にのみ表示） */}
            {isLogin ? (
              <AccountMenu
                menuItems={menuItems}
                postFunc={() => setDrawerOpen(false)}
              />
            ) : null}
            {/* ハンバーガーメニュー */}
            <img
              onClick={() => toggleDrawerMenu()}
              className={
                isDrawerOpen
                  ? spStyles.icon_close_drawer
                  : spStyles.icon_open_drawer
              }
              src={isDrawerOpen ? icon_close_drawer : icon_open_drawer}
              alt="SaaStainer"
            />
          </div>
        </header>
        <div
          className={`${spStyles.drawer} ${
            isDrawerOpen ? spStyles.openDrawer : undefined
          }`}
        >
          <div className={spStyles.upperElementsInDrawer}>
            {/* 導入事例 */}
            <a
              href="https://www.strategit.jp/works/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("header.usecase")}
            </a>
            {/* 運営会社 */}
            <a
              href="https://www.strategit.jp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("header.company")}
            </a>
          </div>
          {divider}
          <div className={spStyles.middleElementsInDrawer}>
            {/* 未認証時に表示 */}
            {isLogin ? null : (
              <>
                {/* 無料会員登録 */}
                <a
                  className={`${spStyles.baseButton} ${spStyles.registrationButton}`}
                  onClick={() => {
                    history.push("/signup")
                    setDrawerOpen(false)
                  }}
                >
                  {t("header.registration")}
                </a>
                {/* ログイン */}
                <a
                  className={`${spStyles.baseButton} ${spStyles.loginButton}`}
                  onClick={() => {
                    history.push("/signin")
                    setDrawerOpen(false)
                  }}
                >
                  {t("header.login")}
                </a>
              </>
            )}
            {/* キーワードで検索 */}
            <SearchByKeyword
              display={location.pathname === "/apps"}
              postFunc={() => setDrawerOpen(false)}
            />
          </div>
          {divider}
          <div className={spStyles.lowerElementsInDrawer}>
            <div className={spStyles.help}>
              <img src={icon_question} />
              {/* 使い方・ヘルプ */}
              <a
                href="https://saastainer.zendesk.com/hc/ja"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("header.help")}
              </a>
            </div>
            <a
              className={spStyles.twitter}
              href="https://twitter.com/SaaStainer"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={icon_twitter} />
            </a>
            {/* 言語切り替え */}
            <div className={spStyles.i18n}>
              <button
                className={
                  language === "ja"
                    ? spStyles.selectedLanguage
                    : spStyles.unSelectedLanguage
                }
                onClick={() => changeLanguage("ja")}
              >
                JP
              </button>
              <p>|</p>
              <button
                className={
                  language === "en"
                    ? spStyles.selectedLanguage
                    : spStyles.unSelectedLanguage
                }
                onClick={() => changeLanguage("en")}
              >
                EN
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  // 横幅768px以上はPCスタイル。横幅767px以下はSPスタイル。
  if (isPC) return pcHeader
  return spHeader
}

export default GlobalHeader
