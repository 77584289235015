import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Theme, ThemeProvider, createMuiTheme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { AppSearchContext, MediaQueryContext } from "../App"
import { EmployerLayoutProps, PublicLayoutProps } from "../typings"
import SnackAlert from "./SnackAlert"
import { SearchBySortKey } from "./SearchBySortKey"
import { SearchByKeyword } from "./SearchByKeyword"
import triangle from "../img/toppage/triangle.png"
import { Pagination } from "@material-ui/lab"
import { AppSummaryCard } from "./AppSummaryCard"
import { getApps } from "../api/apps"
import { AppsData } from "../typings/apps"

/*
コンポーネントの説明：
  アプリ検索結果を表示するコンポーネントです。
  各種検索部品(Service/Category/Keyword/SortKey)が更新したステートをトリガーに
  アプリ検索処理を実行して結果を表示する。
*/
export const SearchResults = (
  props: EmployerLayoutProps | PublicLayoutProps
) => {
  // アプリ情報取得時のエラーハンドラーとアラート用のデータ
  const { alert, errorHandler } = props

  // アプリ検索用のコンテキストからデータを取得
  const { state, dispatch } = useContext(AppSearchContext)

  // 検索結果
  const [results, setResults] = useState<AppsData>()

  // 国際化対応
  const { t } = useTranslation()

  // 検索条件変更検知による検索結果更新
  useEffect(() => {
    updateResults()
  }, [state])

  // ページ変更による検索結果更新
  const onPageChanged = (_: ChangeEvent<unknown>, page: number) => {
    dispatch({ type: "page", payload: { page } })
    updateResults()
  }

  // 検索結果更新処理
  const updateResults = () => {
    getApps({
      keyword: state.keyword,
      appPage: state.page,
      categoryIds: state.categoryIds,
      service: state.serviceName,
      appSortKey: state.sortKey,
    })
      .then((data) => {
        setResults(data)
      })
      .catch(errorHandler)
  }

  // PC・SPで共通のスタイル
  const { isPC } = useContext(MediaQueryContext)
  const pcStyles = useStyles("20px", "340px", "40px")()
  const spStyles = useStyles("18px", "325px", "20px")()
  const styles = isPC ? pcStyles : spStyles

  // 検索結果の存在チェックヘルパー
  const isExistResults = results && results.apps.length > 0

  return (
    // PC・SPで共通のHTML構造
    <div className={styles.root}>
      {/* 吹き出し用画像 */}
      <img id="triangle" src={triangle} />
      {/* アプリタイトルとキーワード検索 */}
      <div className={styles.title}>
        <h3>{t("listApps.listResults")}</h3>
        <SearchByKeyword dropShadow={false} />
      </div>
      {/* ソートキー選択 */}
      <div className={styles.sort}>
        <SearchBySortKey />
      </div>
      {/* アプリ要約カードを並べる */}
      <div className={styles.cards}>
        {isExistResults ? (
          results.apps.map((app) => (
            <AppSummaryCard key={app.id} appData={app} />
          ))
        ) : (
          <div id="noResults">{t("listApps.listNoResults")}</div>
        )}
      </div>
      {/* ページネーション */}
      {isExistResults ? (
        <div className={styles.pagination}>
          <Pagination
            count={results ? results.pageCount : 1}
            page={results ? results.page : 1}
            onChange={onPageChanged}
            color="primary"
          />
        </div>
      ) : null}
      <SnackAlert alert={alert} />
    </div>
  )
}

// PC・SPで共通のスタイル
const useStyles = (fontSize: string, minColmun: string, hPadding: string) =>
  makeStyles((_: Theme) => ({
    // ルート
    root: {
      position: "relative",
      padding: `40px ${hPadding}`,
      backgroundColor: "#f2f2f2",
      "& img[id=triangle]": {
        position: "absolute",
        width: "34px",
        height: "22px",
        top: "-2px",
        left: "calc(100% / 2 - 17px)",
      },
    },
    // 検索タイトルとキーワード検索
    title: {
      display: "grid",
      gap: "10px",
      gridTemplateColumns: "auto 210px",
      alignItems: "center",
      borderBottom: "2px solid #B53230",
      borderImage: "linear-gradient(to right, #B53230 0%, #DD9695 100%)",
      borderImageSlice: 1,
      color: "#B53230",
      fontSize,
      whiteSpace: "nowrap",
    },
    // 並び替え
    sort: {
      display: "flex",
      flexFlow: "row",
      justifyContent: "flex-end",
      paddingTop: "20px",
    },
    // アプリ要約カード表示エリア
    cards: {
      padding: "20px 0px",
      display: "grid",
      gap: "30px",
      gridTemplateColumns: `repeat(auto-fit, minmax(${minColmun}, 0.4fr))`,
      justifyContent: "center",
      "& div[id=noResults]": {
        textAlign: "center",
      },
    },
    // ページネーション
    pagination: {
      paddingTop: "20px",
      display: "flex",
      justifyContent: "center",
    },
  }))
