import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { AppData } from "../typings/apps"
import { Link } from "react-router-dom"
import arrow from "../img/toppage/arrow_app_detail.png"
import { marked } from "marked"

/*
コンポーネントの説明：
  検索結果の一覧に表示されるアプリの要約が記載されたカード。
  クリックするとアプリ詳細画面（/apps/${id}）に遷移する。
*/

// マークダウンをサニタイズ
export const sanitize = (markup: string) => {
  const rawMarkup = marked(markup)
  return { __html: rawMarkup }
}

export const AppSummaryCard = (props: { appData: AppData }) => {
  // アプリデータ取得
  const {
    id,
    name,
    images,
    category,
    aboutApp,
    i18nAboutApp,
    i18nNames,
  } = props.appData

  // 国際化対応
  const { t, i18n } = useTranslation()

  // DB上に登録されているアプリの名称等を暫定的にこの方法で国際化に対応している
  const appName = i18n.language === "ja" ? name : i18nNames.en
  const categoryName = t(`listApps.categories.id${category.id}`)
  const summary =
    i18n.language === "ja" ? aboutApp.summary : i18nAboutApp.en.summary

  // PC・SPで共通のスタイル
  const styles = useStyles()

  return (
    // PC・SPで共通のHTML構造
    <div className={styles.root}>
      <div className={styles.divider}>
        <img className={styles.appImage} src={images.main} />
      </div>
      <h4>{appName}</h4>
      <span className={styles.category}>{categoryName}</span>
      <p
        className={styles.description}
        dangerouslySetInnerHTML={sanitize(summary)}
      />
      <div className={styles.linkToAppDetail}>
        <Link to={`/apps/${id}`}>
          {t(`listApps.chooseButton`)}
          <img src={arrow} />
        </Link>
      </div>
    </div>
  )
}

// PC・SPで共通のスタイル
const useStyles = makeStyles((_: Theme) => ({
  // カードが左から右へフェードインするアニメーション定義
  "@keyframes fadein": {
    "0%": {
      transform: "translate(-200%)",
      opacity: "0",
    },
    "80%": {
      opacity: "0.4",
    },
    "100%": {
      transform: "translate(0)",
      opacity: "1",
    },
  },
  // ルート
  root: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "20px 40px",
    border: 0,
    borderRadius: "8px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.12)",
    backgroundColor: "white",
    animationName: "$fadein",
    animationDuration: "0.5s",
    animationDirection: "normal",
  },
  // アプリ画像
  appImage: {
    display: "block",
    maxWidth: "120px",
    height: "120px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  // 区切り線
  divider: {
    width: "100%",
    borderBottom: "2px solid rgba(184, 184, 184, 0.5)",
  },
  // カテゴリー
  category: {
    display: "inline-block",
    padding: "5px 20px",
    borderRadius: "20px",
    border: "1px solid #B53230",
    color: "#B53230",
  },
  // アプリ説明文(5行目から薄くなっていくよう調整)
  description: {
    position: "relative",
    height: "100px",
    overflow: "hidden",
    "&::after": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      display: "block",
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(to bottom, transparent 0%, transparent 70%, #ffffff 100%)",
      pointerEvents: "none",
    },
  },
  // 詳しく見るリンク
  linkToAppDetail: {
    top: "100px",
    right: "0px",
    display: "flex",
    flexFlow: "row",
    justifyContent: "flex-end",
    width: "100%",
    "& *": {
      display: "inline-block",
    },
    "& img": {
      paddingLeft: "10px",
    },
  },
}))
