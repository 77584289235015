import React from "react"
import { useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  FormControlLabel,
  Checkbox,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { OpenInNew as OpenInNewIcon } from "@material-ui/icons"

/*
コンポーネントの説明：
  利用規約の更新通知を表示するモーダルダイアログコンポーネント。
  - 利用規約の更新内容を表示
  - 「今後表示しない」チェックボックスの状態管理
  - ローカルストレージと連携して表示制御
CSS定義場所：
  コンポーネント固有CSSとして、このファイル内でMaterial-UIのmakeStylesを使用して定義。
*/

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#B53230",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
})

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  content: {
    textAlign: "center",
    "& p": {
      marginBottom: theme.spacing(2),
      lineHeight: 1.6,
    },
  },
  messageContainer: {
    maxWidth: "95%",
    margin: "0 auto",
    textAlign: "left",
  },
  changeList: {
    textAlign: "left",
    margin: `${theme.spacing(3)}px auto`,
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.grey[100],
    borderRadius: "8px",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: "bold",
    display: "inline-flex",
    alignItems: "center",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  button: {
    background: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      background: theme.palette.primary.dark,
    },
    padding: theme.spacing(1, 4),
  },
  checkboxArea: {
    marginTop: theme.spacing(2),
    "& .MuiFormControlLabel-root": {
      whiteSpace: "nowrap",
      width: "100%",
      marginLeft: 0,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "0.9rem",
    }
  },
  actions: {
    justifyContent: "center",
    padding: theme.spacing(2),
  },
}))

interface Props {
  open: boolean
  onClose: (dontShowAgain: boolean) => void
}

const TermsUpdateModal: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [dontShowAgain, setDontShowAgain] = useState(false)

  const handleClose = () => {
    onClose(dontShowAgain)
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        maxWidth="sm"
      >
        <DialogTitle disableTypography className={classes.title}>
          <Typography variant="h6">{t("termsUpdate.title")}</Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
        <div className={classes.messageContainer}>
          <Typography variant="body1">{t("termsUpdate.message1")}</Typography>
          <Typography variant="body1">{t("termsUpdate.message2")}</Typography>
          <Typography variant="body1">{t("termsUpdate.message3")}</Typography>
          </div>
          <Typography variant="body1">
            <a href="/terms" target="_blank" rel="noopener noreferrer" className={classes.link}>
              {t("termsUpdate.link")}
              <OpenInNewIcon fontSize="small" style={{ marginLeft: "4px" }} />
            </a>
          </Typography>
          <div className={classes.checkboxArea}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={dontShowAgain}
                  onChange={(e) => setDontShowAgain(e.target.checked)}
                  color="primary"
                />
              }
              label={t("termsUpdate.dontShowAgain")}
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={handleClose} className={classes.button} variant="contained">
            {t("termsUpdate.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default TermsUpdateModal

