import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { createStyles, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import LaunchIcon from '@material-ui/icons/Launch';
import { MediaQueryContext } from "../App"
/*
コンポーネントの説明：
  トップページのアイキャッチ画像エリアの下に表示するテキストやリンクをまとめたコンポーネント。
CSS定義場所：
  コンポーネント固有CSSとして、このファイル内の最下部で定義。
*/
const AboutSaaStainer = React.memo(() => {
  // コンテキストから必要なリソースを読み込む
  const { isPC } = useContext(MediaQueryContext)
  // 国際化対応
  const { t } = useTranslation()
  // PCスタイル
  const pcStyles = usePCStyles()
  // SPスタイル
  const spStyles = useSPStyles()
  // 横幅768px以上はPCスタイル。横幅767px以下はSPスタイル。
  let styles = spStyles
  if (isPC) styles = pcStyles

  return (
    <div className={styles.root}>
      <p className={styles.mainText}>
        {t("aboutSaaStainer.mainText")}
      </p>
      <p className={styles.subText}>
        <span style={{ fontFamily: "'Jost', sans-serif" }}>{t("aboutSaaStainer.subText1")}</span>
        {t("aboutSaaStainer.subText2")}
      </p>
      <p className={styles.descriptionText}>
        {t("aboutSaaStainer.upperDescriptionText")}
        <br />
        {t("aboutSaaStainer.lowerDescriptionText")}
      </p>
      <p className={styles.link4DevRequest}>
        <a
          href="https://joint-data.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* アプリ開発依頼はこちら */}
          {t("aboutSaaStainer.devRequest")}
        </a>
        <LaunchIcon style={{ fontSize: 17, verticalAlign: 'middle', marginLeft: 5, color: '#B53230' }} />
      </p>
    </div>
  )
})

// PC固有のスタイル定義
const usePCStyles = makeStyles((_: Theme) => ({
  // ルート
  root: {
    textAlign: "center",
    padding: "80px",
    color: "#B53230",
    backgroundColor: "#FFE9E9",
  },
  // メインテキスト
  mainText: {
    fontFamily: "'Jost', sans-serif",
    fontSize: "xx-large",
    fontWeight: "bold",
    letterSpacing: "0.05em",
    marginTop: "10px",
  },
  // サブテキスト
  subText: {
    fontWeight: "bold",
    fontSize: "15px",
    marginBottom: "0px",
  },
  // 説明テキスト
  descriptionText: {
    color: "black",
    fontSize: "15px",
    lineHeight: "2.2",
    marginTop: "10px",
  },
  // 開発依頼リンク
  link4DevRequest: {
    color: "black",
    fontSize: "15px",
    marginTop: "0px",
    lineHeight: "30px",
    "& a": {
      display: "inline-block",
      verticalAlign: "middle",
      paddingRight: "5px",
      whiteSpace: "nowrap",
    },
    "& img": {
      display: "inline-block",
      verticalAlign: "middle",
      height: "17px",
    },
  },
}))

// SP固有のスタイル定義
const useSPStyles = makeStyles((_: Theme) => ({
  // ルート
  root: {
    textAlign: "center",
    color: "#B53230",
    backgroundColor: "#FFE9E9",
    padding: "30px 20px",
  },
  // メインテキスト
  mainText: {
    fontSize: "24px",
    fontWeight: "bold",
    letterSpacing: "0.05em",
    marginTop: "10px",
  },
  // サブテキスト
  subText: {
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "0px",
  },
  // 説明テキスト
  descriptionText: {
    color: "black",
    fontSize: "14px",
    lineHeight: "2.2",
    marginTop: "10px",
  },
  // 開発依頼リンク
  link4DevRequest: {
    color: "black",
    fontSize: "14px",
    marginTop: "0px",
    lineHeight: "30px",
    "& a": {
      display: "inline-block",
      paddingRight: "5px",
      whiteSpace: "nowrap",
    },
    "& img": {
      display: "inline-block",
      verticalAlign: "middle",
      height: "17px",
    },
  },
}))
export default AboutSaaStainer
