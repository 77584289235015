import React, { useContext } from "react"
import { makeStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { AppSearchContext } from "../App"
import { IAppCategoryButton, CategoryId } from "../typings/apps"

/*
コンポーネントの説明：
  アプリ検索部品の１つ。カテゴリーIDを指定してアプリを検索するためのコンポーネント。
  categoriesオブジェクトで定義したカテゴリーIDの数だけボタンを生成する。
  サブミット後はAppSearchContext上にあるステート(categoryIds)を更新するのみで実際の検索処理は委譲する。
*/
export const SearchByCategory = () => {
  // アプリ検索用のコンテキストからデータを取得
  const {state, dispatch} = useContext(AppSearchContext)

  // 国際化対応
const { t } = useTranslation()

// 検索対象のサービス名(＋その他)とボタンの画像URL
const categories: IAppCategoryButton[] = [
    { id: 1, name: t("listApps.categories.id1") },
    { id: 2, name: t("listApps.categories.id2") },
    { id: 3, name: t("listApps.categories.id3") },
    { id: 4, name: t("listApps.categories.id4") },
    { id: 5, name: t("listApps.categories.id5") },
    { id: 6, name: t("listApps.categories.id6") },
    { id: 99, name: t("listApps.categories.id99") },
  ]

  // カテゴリーボタン押下ハンドラー
  const onCategoryButtonClicked = (categoryId: CategoryId) => {
    // 検索条件の更新（カテゴリーID）
    dispatch({type: "category", payload: {categoryId} })
  }

  // PC・SPで共通のスタイル
  const styles = useStyles()

  return (
    // PC・SPで共通のHTML構造
    <div id="categories" className={styles.root}>
      <div className={styles.title}>
        <h3> {t("listApps.categoriesHeader")}</h3>
      </div>
      <div className={styles.buttons}>
        {categories.map((category) => (
          <button
            className={
              state.categoryIds.find(v => v === category.id) ? 
                  `${styles.baseButton} ${styles.selectedButton}` : styles.baseButton
            }
            key={category.id}
            onClick={() => onCategoryButtonClicked(category.id)}
          >
            {category.name}
          </button>
        ))}
      </div>
    </div>
  )
}

// PC・SPで共通のスタイル
const useStyles = makeStyles((_: Theme) => ({
  // ルート
  root: {
    padding: "40px 40px",
    backgroundColor: "white"
  },
  // 検索タイトル
  title: {
    color: "#B53230",
  },
  // ボタンエリア
  buttons: {
    display: "grid",
    gap: "10px",
    gridTemplateColumns: "repeat(auto-fit, minmax(110px, 1fr))",
  },
  // 基本ボタン
  baseButton: {
    height: "60px",
    border: 0,
    borderRadius: "8px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.12)",
    cursor: "pointer",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgba(181, 50, 48, 0.1)",
    },
  },
  // 選択中ボタン
  selectedButton: {
    border: "solid 2px #B53230",
    color: "#B53230",
  },   
}))
