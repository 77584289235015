import React from "react"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Box from "@material-ui/core/Box"
import useStyles from "../styles/teams"
import { useTranslation } from "react-i18next"

export default function Terms() {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box mb={2}>
        <Box
          mb={1}
          fontWeight="fontWeightBold"
          display="flex"
          justifyContent="center"
        >
          {t("terms.chapter1.title")}
        </Box>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter1.article1.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter1.article1.list1")}</li>
          <li>{t("terms.chapter1.article1.list2")}</li>
          <li>{t("terms.chapter1.article1.list3")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter1.article2.title")}
        </Box>
        <div className={classes.paragraph}>
          {t("terms.chapter1.article2.paragraph")}
        </div>
        <ol className={classes.parenthesesOl}>
          <li>{t("terms.chapter1.article2.list1")}</li>
          <li>{t("terms.chapter1.article2.list2")}</li>
          <li>{t("terms.chapter1.article2.list3")}</li>
          <li>{t("terms.chapter1.article2.list4")}</li>
          <li>{t("terms.chapter1.article2.list5")}</li>
          <li>{t("terms.chapter1.article2.list6")}</li>
          <li>{t("terms.chapter1.article2.list7")}</li>
          <li>{t("terms.chapter1.article2.list8")}</li>
          <li>{t("terms.chapter1.article2.list9")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter1.article3.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter1.article3.list1")}</li>
          <li>{t("terms.chapter1.article3.list2")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter1.article4.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter1.article4.list1")}</li>
          <li>{t("terms.chapter1.article4.list2")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box
          mb={1}
          fontWeight="fontWeightBold"
          display="flex"
          justifyContent="center"
        >
          {t("terms.chapter2.title")}
        </Box>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter2.article5.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter2.article5.list1")}</li>
          <li>{t("terms.chapter2.article5.list2")}</li>
          <li>{t("terms.chapter2.article5.list3")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter2.article6.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter2.article6.list1")}</li>
          <li>{t("terms.chapter2.article6.list2")}</li>
          <li>{t("terms.chapter2.article6.list3")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter2.article7.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter2.article7.list1")}</li>
          <li>{t("terms.chapter2.article7.list2")}</li>
          <li>{t("terms.chapter2.article7.list3")}</li>
          <li>{t("terms.chapter2.article7.list4")}</li>
          <li>{t("terms.chapter2.article7.list5")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter2.article8.title")}
        </Box>
        <div className={classes.paragraph}>
          {t("terms.chapter2.article8.paragraph")}
        </div>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter2.article9.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter2.article9.list1")}</li>
          <li>{t("terms.chapter2.article9.list2")}</li>
          <li>{t("terms.chapter2.article9.list3")}</li>
          <li>{t("terms.chapter2.article9.list4")}</li>
          <li>{t("terms.chapter2.article9.list5")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter2.article10.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter2.article10.list1")}</li>
          <li>{t("terms.chapter2.article10.list2")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter2.article11.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter2.article11.list1")}</li>
          <li>{t("terms.chapter2.article11.list2")}</li>
          <li>{t("terms.chapter2.article11.list3")}</li>
          <ol className={classes.parenthesesOl}>
            <li>{t("terms.chapter2.article11.list3_1")}</li>
            <li>{t("terms.chapter2.article11.list3_2")}</li>
            <li>{t("terms.chapter2.article11.list3_3")}</li>
            <li>{t("terms.chapter2.article11.list3_4")}</li>
            <li>{t("terms.chapter2.article11.list3_5")}</li>
            <li>{t("terms.chapter2.article11.list3_6")}</li>
            <li>{t("terms.chapter2.article11.list3_7")}</li>
            <li>{t("terms.chapter2.article11.list3_8")}</li>
            <li>{t("terms.chapter2.article11.list3_9")}</li>
          </ol>
          <li>{t("terms.chapter2.article11.list4")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box
          mb={1}
          fontWeight="fontWeightBold"
          display="flex"
          justifyContent="center"
        >
          {t("terms.chapter3.title")}
        </Box>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter3.article12.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter3.article12.list1")}</li>
          <li>{t("terms.chapter3.article12.list2")}</li>
          <li>{t("terms.chapter3.article12.list3")}</li>
          <li>{t("terms.chapter3.article12.list4")}</li>
          <li>{t("terms.chapter3.article12.list5")}</li>
          <li>{t("terms.chapter3.article12.list6")}</li>
          <li>{t("terms.chapter3.article12.list7")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter3.article13.title")}
        </Box>
        <div className={classes.paragraph}>
          {t("terms.chapter3.article13.paragraph")}
        </div>
        <ol className={classes.parenthesesOl}>
          <li>{t("terms.chapter3.article13.list1")}</li>
          <li>{t("terms.chapter3.article13.list2")}</li>
          <li>{t("terms.chapter3.article13.list3")}</li>
          <li>{t("terms.chapter3.article13.list4")}</li>
          <li>{t("terms.chapter3.article13.list5")}</li>
          <li>{t("terms.chapter3.article13.list6")}</li>
          <li>{t("terms.chapter3.article13.list7")}</li>
          <li>{t("terms.chapter3.article13.list8")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter3.article14.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter3.article14.list1")}</li>
          <ol className={classes.parenthesesOl}>
            <li>{t("terms.chapter3.article14.list1_1")}</li>
            <li>{t("terms.chapter3.article14.list1_2")}</li>
            <li>{t("terms.chapter3.article14.list1_3")}</li>
            <li>{t("terms.chapter3.article14.list1_4")}</li>
            <li>{t("terms.chapter3.article14.list1_5")}</li>
            <li>{t("terms.chapter3.article14.list1_6")}</li>
            <li>{t("terms.chapter3.article14.list1_7")}</li>
            <li>{t("terms.chapter3.article14.list1_8")}</li>
            <li>{t("terms.chapter3.article14.list1_9")}</li>
            <li>{t("terms.chapter3.article14.list1_10")}</li>
            <li>{t("terms.chapter3.article14.list1_11")}</li>
            <li>{t("terms.chapter3.article14.list1_12")}</li>
            <li>{t("terms.chapter3.article14.list1_13")}</li>
          </ol>
          <li>{t("terms.chapter3.article14.list2")}</li>
          <li>{t("terms.chapter3.article14.list3")}</li>
          <li>{t("terms.chapter3.article14.list4")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box
          mb={1}
          fontWeight="fontWeightBold"
          display="flex"
          justifyContent="center"
        >
          {t("terms.chapter4.title")}
        </Box>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter4.article15.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter4.article15.list1")}</li>
          <li>{t("terms.chapter4.article15.list2")}</li>
          <li>{t("terms.chapter4.article15.list3")}</li>
          <li>{t("terms.chapter4.article15.list4")}</li>
          <li>{t("terms.chapter4.article15.list5")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box
          mb={1}
          fontWeight="fontWeightBold"
          display="flex"
          justifyContent="center"
        >
          {t("terms.chapter5.title")}
        </Box>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter5.article16.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter5.article16.list1")}</li>
          <li>{t("terms.chapter5.article16.list2")}</li>
          <li>{t("terms.chapter5.article16.list3")}</li>
          <li>{t("terms.chapter5.article16.list4")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter5.article17.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter5.article17.list1")}</li>
          <li>{t("terms.chapter5.article17.list2")}</li>
          <li>{t("terms.chapter5.article17.list3")}</li>
          <li>{t("terms.chapter5.article17.list4")}</li>
          <li>{t("terms.chapter5.article17.list5")}</li>
          <li>{t("terms.chapter5.article17.list6")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter5.article18.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter5.article18.list1")}</li>
          <li>{t("terms.chapter5.article18.list2")}</li>
          <li>{t("terms.chapter5.article18.list3")}</li>
          <li>{t("terms.chapter5.article18.list4")}</li>
          <li>{t("terms.chapter5.article18.list5")}</li>
          <li>{t("terms.chapter5.article18.list6")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box
          mb={1}
          fontWeight="fontWeightBold"
          display="flex"
          justifyContent="center"
        >
          {t("terms.chapter6.title")}
        </Box>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter6.article19.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter6.article19.list1")}</li>
          <li>{t("terms.chapter6.article19.list2")}</li>
          <li>{t("terms.chapter6.article19.list3")}</li>
          <li>{t("terms.chapter6.article19.list4")}</li>
          <li>{t("terms.chapter6.article19.list5")}</li>
          <li>{t("terms.chapter6.article19.list6")}</li>
          <li>{t("terms.chapter6.article19.list7")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box
          mb={1}
          fontWeight="fontWeightBold"
          display="flex"
          justifyContent="center"
        >
          {t("terms.chapter7.title")}
        </Box>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter7.article20.title")}
        </Box>
        <div className={classes.paragraph}>
          {t("terms.chapter7.article20.paragraph")}
        </div>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter7.article21.title")}
        </Box>
        <ol className={classes.ol}>
          <li>{t("terms.chapter7.article21.list1")}</li>
          <li>{t("terms.chapter7.article21.list2")}</li>
        </ol>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter7.article22.title")}
        </Box>
        <div className={classes.paragraph}>
          {t("terms.chapter7.article22.paragraph")}
        </div>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter7.article23.title")}
        </Box>
        <div className={classes.paragraph}>
          {t("terms.chapter7.article23.paragraph")}
        </div>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          {t("terms.chapter7.article24.title")}
        </Box>
        <div className={classes.paragraph}>
          {t("terms.chapter7.article24.paragraph")}
        </div>
      </Box>
      {/* <Box>
        <Box fontWeight="fontWeightBold">
          返品の取扱条件／返品期限、返品時の送料負担または解約や退会条件
        </Box>
        <Typography>
          解約はサービスごとに当社指定の手続きにより可能。ただし、既に支払われた分に対する返金は不可。
        </Typography>
      </Box> */}
    </Container>
  )
}
