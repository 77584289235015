import i18n from "i18next"
import { initReactI18next } from "react-i18next"

const resources = {
  ja: {
    translation: {
      signin: {
        title: "ログイン",
        bottomLink: "アカウントの新規登録",
        forgotPassLink: "パスワードを忘れた方",
      },
      forgotPassword: {
        title: "パスワードをリセット",
      },
      resetPassword: {
        title: "パスワードの再設定",
      },
      listApps: {
        dummyText: "これはダミーの説明文です。",
        chooseButton: "詳しく見る",
        categories: {
          id1: "開発",
          id2: "人事",
          id3: "会計",
          id4: "勤怠",
          id5: "営業",
          id6: "業務支援",
          id99: "その他",
        },
        headerSubtext:
          "SaaStainerは、SaaSと他のSaaSをデータ連携できるアプリを提供しています。\nAPI連携などのノウハウがなくても、今すぐ簡単に連携を実現できます。\n様々な作業を自動化してSaaS本来のチカラを引き出し、業務改善にお役立てください。",
        listHeader: "全てのアプリ",
        listResults: "検索結果",
        listNoResults: "該当するアプリが見つかりません",
        categoriesHeader: "カテゴリーから探す",
        servicesHeader: "サービスから探す",
        servicesOther: "その他",
        myAppsHeader: "マイアプリ",
        appSortHeader: "並び替え",
        appSortKeys: {
          popularity: "人気順",
          newest: "新着順",
          appNameAsc: "アプリ名,A-Z",
          appNameDesc: "アプリ名,Z-A",
        },
        developmentRrequest: "API連携アプリ開発のご依頼は",
        caseStudy: "導入事例は",
        inquiry: "こちら",
      },
      alertDialog: {
        cancel: "キャンセル",
        execute: "実行",
      },
      appDetailCard: {
        monthly: "月額",
        annually: "年額",
        yen: "円（税込）",
        trial: "トライアル（無料）",
        purchase: "購入",
        unsubscribe: "解約",
        purchased: "購入済み",
        purchasePage: "購入画面",
        appList: "一覧へ戻る",
        detailsHeader: "このアプリについて",
        summaryHeader: "アプリ概要",
        featureHeader: "機能",
        recommendedForHeader: "おすすめな方",
        notesHeader: "ご利用にあたっての注意",
        priceHeader: "価格",
        creatorHeader: "提供元",
        categoryHeader: "カテゴリー",
        openApp: "アプリを開く",
        helpPageHeader: "使い方・ヘルプ",
        help: "詳しく見る",
        helpButton: "使い方を詳しく見る",
        free: "無料",
        comingSoon: "Coming soon",
        inquiry: "アプリに関するお問い合わせはこちら",
        SignUpRequired: "※無料会員登録が必要です",
      },
      appUnsubscribingDialog: {
        dialogTitle: "解約",
        unsubscribe: "解約",
        cancel: "キャンセル",
        contentTextRow1: "ご解約前に、以下のご対応をお願いします（* 必須）",
        contentTextRow2: "・連携アプリの解約方法をご確認",
        contentTextRow3: "・解約アンケートへのご回答",
        linkText: "* 連携アプリの解約方法を確認",
        selectboxLabel: "*解約理由をお選びください",
        selectboxOption1: "利用してない",
        selectboxOption2: "値段が高い",
        selectboxOption3: "使い方がわかりづらい",
        selectboxOption4: "使いたい機能がない",
        selectboxOption5: "他の製品を見つけた",
        selectboxOption6: "その他",
        textAreaLabel: "*選択理由をご記入ください（200文字以内）",
      },
      formCheckbox: {
        saveLoginInfo: "ログイン情報の保持",
      },
      formCheckboxSignUp: {
        noticeMail: "連携に関するメール案内を希望する",
        privacyAgreement: "* 当社の個人情報の取扱規程に同意する(必須)",
        termsAccept: "* 当社の利用規約に同意する(必須)",
      },
      formTextField: {
        email: "メールアドレス",
        password: "パスワード",
        passwordConfirm: "パスワード(確認用)",
        firstName: "名",
        lastName: "姓",
        inquiryText: "お問い合わせ内容",
        hasError: "メールアドレスかパスワードが正しくありません",
        passwordHelperText:
          "パスワードは小文字、大文字、数字を1つ以上含む8文字以上",
        passwordConfirmHelperText:
          "パスワードは小文字、大文字、数字を1つ以上含む8文字以上",
        companyId: "企業ID",
        companyIdHelperText: "半角英数字20文字以内。",
        companyNameHelperText:
          "正式名称で登録ください。（例：株式会社ストラテジット）\n【個人利用の方はお名前をご記入ください】",
        companyName: "企業名",
        personInCharge: "担当者名",
      },
      formForgotPassword: {
        successfulRes: "パスワードリセット用のURLを送信しました。",
      },
      formInquiry: {
        beforeSubmit:
          "当サイトのアプリに関するあらゆるご相談、不具合の報告、その他の問い合わせはこちらからお願い致します。\n\n※受付時間内で拝読し、順次対応いたします。お問い合わせ内容によって回答までお時間をいただくことがございます。\n（受付時間：10:00-17:00）\n\n※年末年始休業：12/26~1/4この期間にいただいたお問い合わせの返信は1月5日以降となります。",
        successfulRes:
          "お問い合わせいただきありがとうございます。\n担当の係員から連絡を差し上げます。\n\n※受付時間内で拝読し、順次対応いたします。お問い合わせ内容によって回答までお時間をいただくことがございます。\n（受付時間：10:00-17:00）\n\n※年末年始休業：12/26~1/4この期間にいただいたお問い合わせの返信は1月5日以降となります。",
        submit: "送信",
      },
      formSelectField: {
        appName: "アプリ名",
      },
      button: {
        submit: "送信",
        update: "更新",
        login: "ログイン",
        register: "登録",
        freeTrial: "無料ではじめる",
      },
      header: {
        company: "運営会社",
        usecase: "導入事例",
        registration: "無料会員登録",
        login: "ログイン",
        placeholder: "キーワードで検索",
        help: "使い方・ヘルプ",
      },
      mainVisual: {
        upperMainText: "すべてのSaaSを",
        lowerMainText: "ボタン１つでラクラク連携",
        freeTrial: "無料で始める",
        videoTitle: "JOINTがわかる動画",
      },
      aboutSaaStainer: {
        mainText: "SaaStainer by JOINTとは",
        subText1: "SaaStainer by JOINT",
        subText2:
          "（サーステイナー byジョイント）は、SaaS活用を支援するAPI連携アプリストアです。",
        upperDescriptionText:
          "アプリストアに掲載されている連携アプリは、API連携のノウハウがなくても、今すぐ簡単に連携を実現できます。",
        lowerDescriptionText:
          "様々な作業を自動化して、SaaS本来のチカラを引き出し、業務改善にお役立てください。",
        devRequest: "アプリ開発依頼はこちら",
      },
      beforeFooterContents: {
        upperMainText: "SaaStainerにないアプリでも",
        lowerMainText: "開発いたしますのでご相談ください",
        upperDescriptionText:
          "JOINTは、自社プロダクトと他SaaSとの連携開発、管理、運用までを効率的に、簡単に、素早く対応できるプラットフォームです。",
        lowerDescriptionText:
          "ストラテジットは国内外50以上のSaaS連携の実装実績もあるため、コンサルティングやサポートなど柔軟に対応させていただきますので、まずはお気軽に無料相談下さい。",
        devRequest: "JOINT iPaaS",
      },
      footer: {
        commercialTransAct: "特定商取引法に基づく表記",
        inquiry: "お問い合わせ",
        summary:
          "SaaStainerは、SaaSの継続的活用を支援するサービスのアプリストアです。\n アプリストアに掲載されている連携アプリは、API連携のノウハウがなくても、今すぐ簡単に連携を実現できます。\n 様々な作業を自動化して、SaaS本来のチカラを引き出し、業務改善にお役立てください。",
        company: "運営会社",
        termsOfService: "利用規約",
        supportServiceTerms: "SaaStainer業務支援サービス利用規約",
        privacy: "プライバシーポリシー",
      },
      listEmployee: {
        email: "メールアドレス",
        firstName: "名",
        lastName: "姓",
        delete: "削除",
        select: "編集",
        unSelect: "取り消す",
        confirmDelete: "削除確認",
        confirmDeleteText: "選択したユーザを削除します。よろしいですか？",
        description:
          "お使いのアプリにアクセスできるユーザーを増やしましょう！\n現在購入・連携しているアプリへのアクセス権限を複数のユーザーに付与することができます。\n登録後に指定されたメールアドレスへ確認登録メールが送信されます。\nメールアドレスはサイト全体で一つのみ使用可能となります。",
        addHeader: "アカウントを追加",
        listHeader: "アカウント一覧",
        editHeader: "従業員情報を編集",
        noEmployees: "アカウントを追加して、アプリ活用の可能性を広げましょう!",
        add: "新規追加",
        list: "一覧",
        edit: "編集",
        cancel: "キャンセル",
      },
      editProfile: {
        header: "アカウント情報",
        description:
          "アカウント情報を変更します。\nパスワードの更新後、メールアドレスへ再度確認メールが送信されます。",
      },
      appPurchased: {
        purchasedText:
          "注文が完了しました。ご購入いただきありがとうございました。",
        toAppList: "一覧画面へ",
      },
      inquiry: {
        inquiry: "お問い合わせ",
        summary:
          "当サイトのアプリに関するあらゆるご相談、不具合の報告、その他の問い合わせはこちらからお願い致します。\n\n※受付時間内で拝読し、順次対応いたします。\nお問い合わせ内容によって回答までお時間をいただくことがございます。\n（受付時間：10:00-17:00）",
      },
      oauth: {
        title: "OAuth 認証",
        signup: "アカウントの新規登録",
      },
      signup: {
        title: "アカウントの新規登録",
        subTitle: "企業IDとは？",
        subSubTitle: "",
        companyIdHelp:
          "企業IDを設定すると、このスペースに貴社の他メンバーを招待し、購入した連携アプリを一緒にお使いいただくことができるようになります。\nIDは自由に設定いただけます。",
        login: "ログイン",
        aboutSaaStainer: "SaaStainerについては",
        registerSaaStainer: "SaaStainerご登録方法については",
        inquiry: "こちら",
      },
      thanksSignup: {
        text:
          "登録を完了するために、メールにて送信したアカウント確認リンクを開いてください。",
      },
      pageTitles: {
        purchasePage: "購入画面",
        appDetailsPage: "アプリの詳細",
        appsPage: "「SaaS x SaaS = 業務改善」",
        employeesPage: "アカウント管理",
        editProfilePage: "アカウントの変更",
        actPage: "特定商取引法に基づく表記",
        termsPage: "「SaaStainer by JOINT」利用規約",
        supportServiceTermsPage: "SaaStainer業務支援サービス利用規約",
        privacyPage: "プライバシーポリシー",
      },
      alerts: {
        loggedIn: "ログインに成功しました",
        updated: "更新しました",
        getDataError: "データの取得に失敗しました",
        loggedOut: "ログアウトしました",
      },
      globalHeader: {
        login: "ログイン",
        signup: "無料ではじめる",
        menuitemProfile: "アカウントの変更",
        menuitemSignout: "ログアウト",
        menuitemEmployees: "アカウント管理",
        menuitemApps: "連携アプリ一覧",
        helpPage: "ヘルプ",
        searchBar: "連携アプリを検索",
      },
      appDetail: {
        relatedApps: "同じサービスの関連アプリ",
        listNoResults: "同じサービスの関連アプリはありません",
      },
      appSearch: {
        appList: "連携アプリ一覧へ戻る",
        listResults: "検索結果 : ",
        listNoResults: "該当するアプリが見つかりません",
      },
      terms: {
        chapter1: {
          title: "第1章　総則",
          article1: {
            title: "第1条（本サービス）",
            list1:
              "この利用規約（以下「本利用規約」といいます。）は、株式会社ストラテジット（以下「当社」といいます。）が利用者に提供・運営するアプリストアサービス「SaaStainer by JOINT」（以下「本サービス」といい、理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスも本サービスに含みます。）及び本サービスを通じて提供する連携プロダクトについて、利用者が利用する際の一切の行為に適用されます。",
            list2:
              "本サービス及び連携プロダクトをご利用いただくには、予め本利用規約に同意の上、利用契約の締結を行うものとします。利用契約の申込み（以下「利用申込み」といいます。）によって、利用者は本利用規約及びこれに関連する規約・通知等のすべてに同意したものとみなされます。",
            list3:
              "本サービス及び連携プロダクトは、キャンペーンその他の利用に関してのみ適用される特則（本利用規約の下位規約）を、本利用規約と別途提示する場合がございます。その場合、当該利用については、当該特則に定めのある事項については特則が本利用規約に優先され、特則に定めのない事項については本利用規約が適用されます。",
          },
          article2: {
            title: "第2条（定義）",
            paragraph:
              "本利用規約において使用する以下の用語は各々以下に定める意味を有するものとします。",
            list1:
              "利用者：本利用規約に同意の上、利用契約を締結した法人、団体又は個人を意味します。",
            list2:
              "利用希望者：本サービスの利用を希望する法人、団体又は個人を意味します。",
            list3:
              "利用者情報：利用希望者及び利用者が利用契約に基づき登録、追加、変更した情報を意味します。",
            list4:
              "利用契約：当社と利用希望者との間で締結する当社が利用者に本件サービスを利用させる契約を意味します。",
            list5:
              "コンテンツ：プログラム・文章・画像・映像等の情報を意味しますが、これらに限りません。",
            list6:
              "本アプリ：本サービスの一環として、当社が使用を許諾するアプリケーション及び関連ソフトウェアを意味します。",
            list7:
              "連携プロダクト：第三者の提供するサービスのうち、本サービスによって利用者が利用可能なものを意味します。",
            list8:
              "連携アプリ：連携プロダクトにより提供されるアプリケーションソフトのうち、本サービスによって利用者が利用可能なものを意味します。",
            list9:
              "本サイト：当社が運営する「https://www.strategit.jp/」および「https://saastainer.com/」のサイトを意味し、理由の如何を問わず、URLが変更された場合は、当該変更後のURL先のサイトを意味するものとします。",
          },
          article3: {
            title: "第3条（通知）",
            list1:
              "当社が利用者に対して本サービス及び連携プロダクトに関する各種通知を行う必要があると判断した場合、本サイトへの掲載、電子メール、書面、又は、これらによることが困難な場合は当社が適当と判断する方法により随時これを行います。",
            list2:
              "前項の通知は、電子メール及び書面により行う場合は、当社が発信した時点、本サイトへの掲載により行う場合は、当該通知の内容を本サイトに掲載した時点をもって、全ての利用者に到達したものとみなします。",
          },
          article4: {
            title: "第4条（本利用規約の変更）",
            list1:
              "当社は、当社の判断により、本利用規約をいつでも任意の理由で追加・変更・削除（以下「追加等」といいます）することができるものとし、利用者は、当社が本利用規約を追加等すること並びに本サービス及び連携プロダクトの利用条件は追加等後の利用規約に定める内容によることを予め承諾します。",
            list2:
              "当社が本利用規約を変更した場合は、本サイトにて公開される利用規約の変更時点より効力を生じるものとします。変更後、利用者が本サービス若しくは連携プロダクトを利用した場合又は当社の定める期間（個別に定めなかった場合には、30日間とします）内に当社に対して異議を述べなかった場合には、利用者は、本利用規約の変更に同意したものとみなします。",
          },
        },
        chapter2: {
          title: "第2章　本サービス",
          article5: {
            title: "第5条（対象アプリの利用）",
            list1:
              "利用者は、本利用規約に規定された条件の下で連携本アプリ及び連携アプリを非独占的に利用することができます。",
            list2:
              "本アプリ及び連携アプリは、利用者が自ら機能を使用する目的でのみ使用することができ、第三者のサービスに関連して、又は、販売、配布、開発又はその他一切の他の目的のために使用することはできません。",
            list3:
              "利用者は、本アプリ及び連携アプリに関するプログラムのリバースエンジニアリングを行い、又はその一部若しくは全部を複製、修正、変更、改変若しくは翻案したり、第三者に開示してはなりません。",
          },
          article6: {
            title: "第6条（利用申込み）",
            list1:
              "利用者は、本利用規約及びこれに関連して当社が利用者に通知した規約等を変更なく受け入れることを条件として、当社指定の方法により、本サービス及び連携プロダクトの利用申込みを実施し、当社により利用開始の通知を受けることで、利用契約が成立し、本サービス及び連携プロダクトの提供を受けることができます。",
            list2:
              "利用希望者は、利用申込みに際し、本利用規約に同意するものとします。なお、利用申込みを行った利用希望者は、本利用規約及びこれに関連する規約・通知等の全てを変更なく受け入れたことに同意したものとみなします。",
            list3:
              "利用者が、本利用規約に同意し、本サービス又は連携プロダクトを利用する場合、利用者は、利用者の所属する法人又はその他の団体(以下「法人等」という。)の同意のもと利用申込みをしているものとします。ただし、利用者が、利用者の所属する法人等に利用契約の効果を帰属できなかった場合は、本利用規約に同意して本サービスを利用した当該利用者にその一切の責任が発生するものとします。このとき、当社の選択に従い、利用契約の履行又は利用契約の取消しが選択されるものとします。",
          },
          article7: {
            title: "第7条（本サービスの提供）",
            list1:
              "当社は、本サービス及び連携プロダクトの提供又は本サービス及び連携プロダクト上で提供される全てのデータについて、その正確性、完全性、合目的性、確実性、有効性、安全性、適法性、可用性等を実現するよう努めますが、これにつき如何なる保証も行わず、一切責任を負わないものとします。",
            list2:
              "利用者は、本サービスにより連携プロダクト及びその他の第三者より取得した情報を利用する場合、その行為により生ずる結果全てについて利用者自身で責任を負うものとし、当社は一切の責任を負わないものとします。",
            list3:
              "本サービスを利用することによる、連携プロダクトへのアクセス、利用者情報の取得・提出、連携プロダクト内における認証情報の自動ログインは、利用者自身が本サービス及び対象サービスを利用することにより自らの意思で行う行為であり、利用者は、これらの行為により生ずる結果全てについて責任を負うものとし、当社は一切の責任を負わないものとします。",
            list4:
              "利用者は、本サービスを利用して取得した全ての情報に関連する一切の判断を利用者自身の責任の元で行うこと及び当社は、当該判断により行われた取引などの一切の結果について責任を負わないことを予め了承するものとします。",
            list5:
              "利用者は、本利用規約とは別に特に定められた連携プロダクトの利用規約を自己の費用と責任で遵守するものとし、利用者と連携プロダクトを運営する第三者との間で紛争等が生じた場合でも、当社は当該紛争等について一切の責任を負いません。",
          },
          article8: {
            title: "第8条（有効期間）",
            paragraph:
              "利用契約の有効期間は、利用契約に別段の定めがない限り、利用契約成立日から1年間とします。ただし、契約期間満了の30日前までに、書面による契約終了の意思表示がなされない場合、利用契約は1年間更新されるものとし、今後も同様とします。",
          },
          article9: {
            title: "第9条（利用者の解約）",
            list1:
              "利用者は、解約日の30日前までに当社規定の方法により申し出ることによって、契約期間中に利用契約を解約することができます。",
            list2:
              "利用者は、前項の解約後も、当社及び第三者に対する、本サービス及び連携プロダクトに関する一切の義務及び債務（損害賠償を含みますが、これに限りません）を免れるものではないことを、予め異議なく承諾するものとします。",
            list3:
              "当社は、本条に基づく利用契約の解約により利用者、当社及び第三者に生じた損害につき、一切責任を負いません。",
            list4:
              "解約後、再度本サービスの登録を希望する際は、再度利用申込みをする必要があります。利用者は再度の利用申込み後、解約前の本データが引き継がれないことを了承するものとします。",
            list5:
              "解約後、利用者により作成されたデータは消去されます。利用者は、解約に際して、利用者により作成されたデータを引き渡すよう要求することはできません。",
          },
          article10: {
            title: "第10条（本利用規約上の地位の譲渡等）",
            list1:
              "利用者は、当社の書面による事前の承諾なく、利用契約上の地位又は本利用規約に基づく権利義務の全部若しくは一部を、第三者に貸与、譲渡（合併、会社分割等による包括承継も含みますが、これらに限られません）、名義変更し、又は担保の目的に供することはできません。",
            list2:
              "当社は、当社が本サービス又は連携プロダクトについての事業を他社に承継させる場合（事業譲渡、合併、会社分割による場合を含みますが、これらに限られません）には、利用契約上の地位、本利用規約に基づく権利及び義務ならびに利用者情報その他の顧客情報を譲受人に承継させることができるものとし、利用者は、かかる承継について予め同意したものとみなします。",
          },
          article11: {
            title:
              "第11条（本サービス及び連携プロダクトの変更、追加、廃止及び中断等）",
            list1:
              "当社は、当社の判断により、本サービス及び連携プロダクトの内容の全部又は一部を変更、追加、廃止することができるものとします。",
            list2:
              "当社は、前項の場合には、第3条の規定に従い利用者に通知を行います。但し、緊急の場合は事後的に通知を行い、又は、通知を行わない場合があります。",
            list3:
              "当社は、以下各号の事由が生じた場合には、利用者に事前に通知することなく、本サービス又は連携プロダクトの一部又は全部を一時的に中断することができます。",
            list3_1:
              "本サービス又は連携プロダクト用のハード・ソフト・通信機器設備等に関わるメンテナンスや修理を定期的又は緊急に行う場合",
            list3_2: "電気通信事業者の役務が提供されない場合",
            list3_3:
              "本サービス又は連携プロダクトに著しい負荷や障害が与えられることによって正常なサービスを提供することが困難な場合",
            list3_4:
              "本サービス又は連携プロダクトに対するデータの改ざん、ハッキング等本サービスを提供することにより利用者又は第三者等が著しい損害を受ける可能性を当社が認知した場合",
            list3_5:
              "天災等の不可抗力により本サービス又は連携プロダクトの提供が困難な場合",
            list3_6:
              "火災、停電、その他の不慮の事故により本サービス又は連携プロダクトの提供が困難な場合",
            list3_7:
              "戦争、紛争、動乱、暴動、労働争議等により本サービス又は連携プロダクトの提供が困難な場合",
            list3_8:
              "当社又はデータプロバイダーの事情により、本サービス又は連携プロダクトを通じて当該データプロバイダーから情報を取得することができない場合",
            list3_9: "その他前各号に準じ当社が必要と判断した場合",
            list4:
              "当社は、本サービス又は連携プロダクトの利用希望者、利用者または第三者に対して、本条第1項乃至第3項に定める変更、追加、廃止、中断による損害について、当社に故意又は重過失のない場合、一切の責任を負わないものとします。",
          },
        },
        chapter3: {
          title: "第3章　利用者の責任等",
          article12: {
            title: "第12条（利用者の義務）",
            list1:
              "利用者は、本サービス及び連携プロダクトを利用するための通信機器やソフトウェア、電話利用契約の締結、携帯端末利用契約の締結、インターネットサービスプロバイダへの加入等を自己の費用と責任において準備、実施するものとします。",
            list2:
              "当社は、本サービス及び連携プロダクトを利用するためのインストール作業等に伴う不具合など、本サービス及び連携プロダクトを利用するにあたって発生し得る不具合により利用者又は第三者に生じた不利益や損害（本サービス以外のデータ、ソフトウェア、又はハードウェア等に生じた損害等の一切を含みます）について、当社に故意または重過失のない場合、一切の責任を負わないものとします。",
            list3:
              "利用者は、利用者情報に変更があった場合には、遅滞なく当社に対して当社指定の方法により報告するものとします。",
            list4:
              "利用者は、前項の報告を怠ったことにより当社からの通知が不到達となった場合、当該通知は通常到達すべき時に到達したとみなされることを予め異議なく承諾するものとします。利用者が前項の変更を怠ったことにより利用者に生じた損害について、当社は一切責任を負わないものとします。",
            list5:
              "利用者は、本サービス又は連携プロダクトの利用により、当社又は第三者に対して損害を与えた場合（利用者が本利用規約に違反したことにより、当社又は第三者が損害を被った場合を含みます）には、自己の責任と費用をもって処理及び解決するものとします。また、利用者の行為により第三者から当社が損害賠償請求を受けた場合には、当該利用者は当社に対し、当社に生じた損害及び費用（合理的範囲内の弁護士費用等の間接的な損害及び費用を含みます）を補償するものとします。",
            list6:
              "本サービスを利用することによる、本アプリの機能を用いた連携プロダクトへのアクセス、情報の取得又は提出、連携プロダクトへの自動ログインは、利用者自身が本サービスを利用することにより自らの意思で行う行為であり、利用者は、これらの行為により生ずる結果全てについて責任を負うものとします。当社は、これらの行為の当事者、使者、代理人又は仲立人等とならず、これらの行為により生ずる結果について一切責任を負わないものとします。",
            list7:
              "利用者は、本サービス又は連携プロダクトを利用してなされた一切の行為とその結果について、全ての責任を負うものとします。また、利用者は、本サービスの利用に関して第三者から問い合わせ、クレームを受けた場合には、自己の責任と費用をもって処理及び解決するものとします。",
          },
          article13: {
            title: "第13条（禁止事項）",
            paragraph:
              "利用者は、本サービス及び連携プロダクトの利用に関し、以下の行為を行ってはならないものとします。利用者が以下の行為を行った場合、当社は、当該行為により当社に生じた一切の損害等の賠償を求めることができるものとします。",
            list1:
              "本サービス又は連携プロダクトの運営を妨げ、その他本サービス又は連携プロダクトの提供に支障をきたす行為、又はそれらのおそれのある行為",
            list2:
              "他の利用者、第三者、及び当社の著作権、プライバシーその他の権利を侵害する行為、又はそれらのおそれのある行為",
            list3:
              "公序良俗に反する行為その他法令に違反する行為、又はそれらのおそれのある行為",
            list4: "当社又は利用者が所属する業界団体の内部規則に違反する行為",
            list5:
              "コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為",
            list6:
              "本サービス又は連携プロダクトに関し利用しうる情報を改ざんする行為",
            list7:
              "当社が定める一定のデータ容量以上のデータを本サービス又は連携プロダクトを通じて送信する行為",
            list8: "その他当社が不適当と判断する行為",
          },
          article14: {
            title: "第14条（アプリの使用停止及び解約）",
            list1:
              "当社は、利用者が下記の事項の何れか一つにでも該当することが判明した場合、当該利用者の本サービス又は連携プロダクトの利用権を停止し、1ヶ月の期間を定めた催告を行うことができるものとし、当該利用者は予め異議無くこれを承諾するものとします。",
            list1_1:
              "利用者が本利用規約に違反する行為を行ったと当社が合理的に判断した場合",
            list1_2:
              "本サービス又は連携プロダクトの運営・保守管理上、必要であると合理的に判断した場合",
            list1_3:
              "当社が指定する決済方法の不正使用が判明し又は決済サービス会社より利用者の決済を停止若しくは無効扱いとされた場合",
            list1_4:
              "成年被後見人が本サービスを利用した場合、又は未成年者・被保佐人・被補助人が、法定代理人・保佐人・補助人等の同意なく本サービスを利用した場合",
            list1_5:
              "利用者が死亡した場合、又は後見開始、保佐開始若しくは補助開始の審判を受けた場合",
            list1_6: "利用者情報に虚偽の事実があることが判明した場合",
            list1_7:
              "手段の如何を問わず、本サービス又は連携プロダクトの運営を妨害した場合",
            list1_8:
              "支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合",
            list1_9:
              "自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けたとき",
            list1_10:
              "差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合",
            list1_11: "租税公課の滞納処分を受けた場合",
            list1_12:
              "反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合",
            list1_13: "その他前各号に類する事由があると当社が判断した場合",
            list2:
              "当社は、前項に基づいて利用者の本サービス又は連携プロダクトの利用権を停止した場合、当該利用者が停止から1ヶ月以内に、前項所定の事由に関する当社の判断について重大な誤りがないことを立証しない限り、別途の通知等を要さず、当該利用者との利用契約を解約することができます。但し、次項に該当する場合、当該規定が本項に優先します。",
            list3:
              "前項に関わらず、当社は、利用者が本条第1項所定の事由に該当し、かつ、当社の業務に支障をきたすと当社が判断した場合には、1項の催告なしに直ちに利用契約を解約することができるものとし、利用者はこれを予め異議なく承諾するものとします。利用者は、本条に基づく解約後も、当社及び第三者に対する一切の義務（損害賠償義務を含みますが、これに限りません）を免れるものではないことを、予め異議なく承諾するものとします。",
            list4:
              "当社は、本条に基づく本サービス又は連携プロダクトの利用権停止又は解約及びそれに伴う行為により利用者及びその他の第三者に生じた損害につき、一切責任を負いません。",
          },
        },
        chapter4: {
          title: "第4章　料金",
          article15: {
            title: "第15条（利用料金）",
            list1:
              "本サービスは原則として有料で提供します。利用者は、当社が別途定める利用料金表に従い、利用料金その他の料金、費用等（以下「利用料金等」といいます）を当社に対し、当社が指定する決済方法に従い、当月末締めで、翌月末までに支払うことに同意します。",
            list2:
              "前項に従い当社に対し支払われた利用料金は、対応する手続が完了した場合は、いかなる理由（第9条の解約や第14条の本サービス利用権の停止又は解約を含みますが、これらに限られません）があっても返還を行いません。",
            list3:
              "当社が利用料金表を変更した場合は、第3条の規定に従い利用者に通知します。",
            list4:
              "本条に定める利用料金等について、別途締結する契約書、覚書等がある場合には当該契約書、覚書等が優先するものとします。また定めのない事項に関しては本利用規約が優先するものとします。",
            list5:
              "利用者が本条に基づく利用料金その他の本契約に基づく金銭債務の支払いを遅延したときは、当該時点で本利用規約第8条第1項に定める契約期間が終了するまでの利用料金等のうち既払い分を除く全残額について期限の利益を失うものとし、支払期日の翌日から支払済みに至るまで、年14.6%（年365日日割計算）の割合による遅延損害金を支払うものとします。",
          },
        },
        chapter5: {
          title: "第5章　情報・権利の取扱",
          article16: {
            title: "第16条（秘密保持）",
            list1:
              "本利用規約において「秘密情報」とは、利用規約又は本サービスに関連して、当社又は利用者が、相手方より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、相手方の技術、営業、業務、財務、組織、その他の事項に関する全ての情報（本サービスの不具合の発生によって知ったこととなったエラーコード等の情報を含みますがこれに限られません。）を意味します。但し、(1)提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は既に知得していたもの、(2)提供若しくは開示又は知得した後、自己の責めに帰せざる事由により刊行物その他により公知となったもの、(3)提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの、(4)秘密情報によることなく単独で開発したもの、(5)秘密保持の必要なき旨書面で確認されたものについては、秘密情報から除外するものとします。",
            list2:
              "当社及び利用者は、秘密情報を本サービス及び連携プロダクトの利用の目的のみに利用するとともに、相手方の書面による承諾なしに第三者(当社の委託先を含まない。)に当社の秘密情報を提供、開示又は漏洩しないものとします。",
            list3:
              "前項の定めに拘わらず、当社又は利用者は、法律、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請があった場合、速やかにその旨を相手方に通知しなければなりません。",
            list4:
              "当社及び利用者は、相手方から求められた場合にはいつでも、遅滞なく、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄しなければなりません。ただし、次条3項に基づき当社が既に廃棄した情報を除きます。",
          },
          article17: {
            title: "第17条（個人情報及び利用者情報の取り扱い）",
            list1:
              "当社は、利用者から受領する個人情報を、別途当社が定めるプライバシーポリシーに従って適切に取り扱います。",
            list2:
              "当社は、利用者が当社に提供した利用者情報（個人情報、本サービスの利用により発生したアクセスログ、利用時間等の利用に係る情報を含みます）を収集し、保存し、当社の裁量で、本サービスの提供及び運用、サービス内容の改良及び向上、本サービスの利用状況の把握等の目的のために利用し、又は個人を特定できない形での統計的な情報として、企業に対する提案又はコンサルティング、新サービスの開発その他の目的のために利用することができるものとします。",
            list3:
              "当社は、本サービス及び連携プロダクト提供並びにこれらに付随する業務に必要な範囲で、利用者の個人情報を取得した場合、当該取得の目的との関係で利用者の個人情報を保持する必要がなくなった時点で、直ちに当該個人情報を廃棄するものとします。",
            list4:
              "当社は、契約終了等により利用者に対する本サービス提供を終了したとき、利用者の個人情報、利用者情報その他利用者に関する情報を、すべて廃棄するものとします。",
            list5:
              "当社は、利用者の名称を当社導入企業として公開することができるものとします。この場合、当社は事例を公開するために必要な範囲で利用者のロゴ及び商標等を無償で使用できるものとします。",
            list6:
              "利用者は、前項の場合には、当該公開又はロゴ及び商標等使用の停止や削除、その他必要な措置を請求することができるものとします。",
          },
          article18: {
            title: "第18条（著作権等の帰属）",
            list1:
              "利用者が本サービス又は連携プロダクトを通じて当社に送信したコンテンツに関する著作権その他一切の知的財産権は、利用者に帰属します。利用者は、当社がこれらのコンテンツを、本サービスの運営に必要な限度で利用することについて、あらかじめ承諾するものとします。",
            list2:
              "本サービス又は連携プロダクトにおいて、当社が利用者に提供するコンテンツ、本サービス及び連携プロダクトに関する著作権その他一切の知的財産権は、当社又は当社に権利の使用を許諾したライセンサーに帰属します。利用者は、著作権法に定められた範囲を超えて、当社及びライセンサーの許諾を得ずにコンテンツの翻訳、編集及び改変等を行い、又は第三者に使用させたり公開することはできません。",
            list3:
              "当社又は利用者が本条の規定に違反して問題が生じた場合、違反した当事者は、自己の費用と責任において問題を解決するものとします。",
            list4:
              "本サービス及び連携プロダクトは日本国内をサービス提供対象とし、当社は日本国外における権利者の知的財産権につき如何なる保証も行わず、一切の責任を負いません。",
            list5:
              "当社ホームページ又は本アプリ若しくは連携アプリ上には商標、ロゴ及びサービスマーク等が表示される場合がありますが、当社は、本利用規約により利用者その他の第三者に対し何ら当該商標の使用を許諾するものではありません。",
            list6:
              "利用者は、本サービス又は連携プロダクトに関わる発明その他の知的財産に関し特許権、実用新案権、意匠権、商標権の出願を申請したり、著作権に関する登録を申請してはならないものとします。",
          },
        },
        chapter6: {
          title: "第6章　免責",
          article19: {
            title: "第19条（損害賠償の制限）",
            list1:
              "利用者は、本サービス及び連携プロダクトを利用することが、利用者に適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、利用者による本サービス及び連携プロダクトの利用が、利用者に適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。",
            list2:
              "本サービスに関連して利用者と他の利用者、第三者プロダクトを運営する事業者等その他の第三者との間において生じた取引、連絡、紛争等については、利用者の責任において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。",
            list3:
              "当社は、当社による本サービス又は連携プロダクトの提供の中断、停止、終了、利用不能又は変更、本データの削除又は消失、利用契約の解除、本サービスの利用による本データの消失又は機器の故障若しくは損傷、その他本サービスに関連して利用者が被った損害につき、当社に故意又は重過失のない場合、賠償する責任を一切負わないものとします。",
            list4:
              "当社ホームページから他のウェブサイトへのリンク又は他のウェブサイトから本サイトへのリンクが提供されている場合でも、当社は、本サイト以外のウェブサイト及びそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。",
            list5:
              "前各項に定める他、当社は、本サービス及び連携プロダクトにより利用者又は第三者に生じた全ての損害に対して、一切の責任を負いません。",
            list6:
              "当社は、利用者又は第三者に発生した機会逸失、業務の中断その他の損害（間接損害や逸失利益を含みます）に対して、損害の可能性を事前に通知されていたとしても、一切の責任を負いません。",
            list7:
              "当社に故意又は重過失がある場合その他消費者契約法の適用等の理由により、本項その他当社の損害賠償責任を免責する規定にかかわらず当社が利用者に対して損害賠償責任を負う場合においても、当社の賠償責任は、損害の事由が生じた時点から遡って直近1ヶ月の期間に利用者から現実に受領した本サービスの利用料金の総額（当該期間中に本サービスの利用料金を受領していない場合には1000円）を上限とします。",
          },
        },
        chapter7: {
          title: "第7章　雑則",
          article20: {
            title: "第20条（完全合意）",
            paragraph:
              "本利用規約は、本利用規約に含まれる事項に関する当社と利用者との完全な合意を構成し、口頭又は書面を問わず、本利用規約に含まれる事項に関する当社と利用者との事前の合意、表明及び了解に優先します。",
          },
          article21: {
            title: "第21条（分離可能性及び有効性）",
            list1:
              "本利用規約のいずれかの条項又は一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本利用規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及び利用者は、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。",
            list2:
              "本利用規約のいずれかの条項又は一部が、ある利用者との関係で無効とされ、又は取り消された場合でも、当該条項又は一部は、その他の利用者との関係では有効とします。",
          },
          article22: {
            title: "第22条（存続規定）",
            paragraph:
              "第5条第2項、第3項、第7項第1項、第3項から第6項、第8条、第9条、第10条、第12条第2項、第5項から第8項まで、第13条、第14条第4項、第15条、並びに第16条から第24条の規定は利用契約の終了後も有効に存続するものとします。",
          },
          article23: {
            title: "第23条（準拠法）",
            paragraph:
              "本利用規約、及びこれに関する一切の事項については、日本国法を準拠法とし、本利用規約は、日本国法に従って解釈されるものとします。",
          },
          article24: {
            title: "第24条（合意管轄裁判所）",
            paragraph:
              "本サービス又は連携プロダクトに関連して、利用者と当社の間で問題が生じた場合、両者は誠意を持って協議するものとし、協議しても解決しない場合には東京地方裁判所を第一審の専属的合意管轄裁判所とします。",
          },
        },
      },
      termsUpdate: {
        title: "利用規約改定のお知らせ",
        message1:
          "平素よりSaaStainerをご利用いただき、誠にありがとうございます。",
        message2:
          "3月1日よりSaaStainerは、ストラテジット社が提供するJOINTプラットフォームの1つ製品としてSaaStainer By JOINTへ名称変更しました",
        message3: "それに伴い利用規約を改定いたしましたのでお知らせします。",
        link: "利用規約を確認する",
        dontShowAgain: "今後このメッセージを表示しない",
        close: "確認しました",
      },
    },
  },
  en: {
    translation: {
      signin: {
        title: "Login",
        bottomLink: "Sign up",
        forgotPassLink: "Forgot password?",
      },
      forgotPassword: {
        title: "Reset Password",
      },
      resetPassword: {
        title: "Reset Password",
      },
      listApps: {
        dummyText: "Dummy Text",
        chooseButton: "Details",
        categories: {
          id1: "Development",
          id2: "HR",
          id3: "Accounting",
          id4: "Attendance",
          id5: "Sales",
          id6: "Business Support",
          id99: "Other",
        },
        headerSubtext:
          "SaaStainer is a place for SaaS x SaaS applications.\nEven without know-how about APIs you are able construct SaaS integration systems.\nBy automating business tasks, take advantage of SaaS's inner power to make your daily operations more efficient.",
        listHeader: "All apps",
        listResults: "Search results",
        listNoResults: "No apps match search conditions",
        categoriesHeader: "App categories",
        servicesHeader: "App services",
        servicesOther: "Other",
        myAppsHeader: "My apps",
        appSortHeader: "Sort",
        appSortKeys: {
          popularity: "Popularity",
          newest: "Newest",
          appNameAsc: "App Name,A-Z",
          appNameDesc: "App Name,Z-A",
        },
        developmentRrequest:
          " to request API-connected application development.",
        caseStudy: " for case studies.",
        inquiry: "Click here",
      },
      alertDialog: {
        cancel: "Cancel",
        execute: "Execute",
      },
      appDetailCard: {
        monthly: "Per Month",
        annually: "Per Year",
        yen: "Yen (including tax)",
        trial: "Free trial",
        purchase: "Purchase",
        unsubscribe: "Unsubscribe",
        purchased: "Purchased",
        purchasePage: "Purchase",
        appList: "Apps List",
        detailsHeader: "About this app",
        priceHeader: "Cost",
        summaryHeader: "App Summary",
        featureHeader: "Feature",
        recommendedForHeader: "Recommended for",
        notesHeader: "Notes on Use",
        creatorHeader: "Created by",
        categoryHeader: "Category",
        openApp: "Go to app",
        helpPageHeader: "How to use",
        help: "See more details",
        helpButton: "See more details",
        free: "Free",
        comingSoon: "Coming Soon",
        inquiry: "Contact us for app inquiries",
        SignUpRequired: "*Free registration is required",
      },
      appUnsubscribingDialog: {
        dialogTitle: "Unsubscribe",
        unsubscribe: "Unsubscribe",
        cancel: "cancel",
        linkText: "Check the notes on unsubscribing.(Required)",
        contentTextRow1:
          "Before proceeding with unsubscription, please complete the following steps (* Required)",
        contentTextRow2:
          "・Please confirm the method for unsubscribing from linked applications",
        contentTextRow3: "・Please respond to the unsubscription survey",
        selectboxLabel: "*Please select a reason for unsubscription",
        selectboxOption1: "Not using",
        selectboxOption2: "Too expensive",
        selectboxOption3: "Difficult to use",
        selectboxOption4: "Missing features I want",
        selectboxOption5: "Found another product",
        selectboxOption6: "Other",
        textAreaLabel:
          "*Please provide details for your reason (within 200 characters)",
      },
      formCheckbox: {
        saveLoginInfo: "Save login status",
      },
      formCheckboxSignUp: {
        noticeMail: "Accept e-mail regarding API integration",
        privacyAgreement: "Accept privacy policy of our company(Required)",
        termsAccept: "Accept terms of our company(Required)",
      },
      formTextField: {
        email: "Email",
        password: "Password",
        passwordConfirm: "Password(Confirmation)",
        firstName: "First Name",
        lastName: "Last Name",
        inquiryText: "Inquiry",
        hasError: "Email or password are incorrect",
        passwordHelperText:
          "Includes undercase, uppercase, and numeric characters(8 or more)",
        passwordConfirmHelperText:
          "Includes undercase, uppercase, and numeric characters(8 or more)",
        companyId: "Company ID",
        companyIdHelperText: "Alphanumeric characters(20 or less)",
        companyNameHelperText: "Enter full company name",
        companyName: "Company Name",
        personInCharge: "Person in Charge",
      },
      formForgotPassword: {
        successfulRes: "Sent a URL to reset your password",
      },
      formInquiry: {
        beforeSubmit:
          "Please feel free to let us know what you think about our apps, or anything else.\n\nWe will get back to you as soon as we can during out support hours: 10:00-17:00 JST.",
        successfulRes:
          "Thank you for your inquiry.\n We will get back to you as soon as possible.",
        submit: "Send",
      },
      formSelectField: {
        appName: "App",
      },
      button: {
        submit: "Send",
        update: "Update",
        login: "Login",
        register: "Register",
        freeTrial: "Free Trial",
      },
      header: {
        company: "Company",
        usecase: "Usecase",
        registration: "Free Trial",
        login: "login",
        placeholder: "Search by keyword",
        help: "How to Use",
      },
      mainVisual: {
        upperMainText: "Easy integration",
        lowerMainText: "of all SaaS",
        freeTrial: "Free Trial",
        videoTitle: "Video shows JOINT",
      },
      aboutSaaStainer: {
        mainText: "What is SaaStainer by JOINT?",
        subText1: "SaaStainer by JOINT",
        subText2:
          " is an API integration app store that supports SaaS utilization.",
        upperDescriptionText:
          "The integration apps listed in the app store can be easily connected immediately, even without API integration expertise.",
        lowerDescriptionText:
          "Automate various tasks to unleash the full potential of SaaS and improve your business operations.",
        devRequest: "request development here",
      },
      beforeFooterContents: {
        upperMainText: "We can develop applications that are not in SaaS,",
        lowerMainText: "so please consult with us.",
        upperDescriptionText:
          "JOINT is a platform that enables efficient, easy, and quick handling of the development, management, and operation of integration between in-house products and other SaaS.",
        lowerDescriptionText:
          "Strategit has experience in implementing more than 50 SaaS linkages both domestically and internationally, so please feel free to contact us for a free consultation and support.",
        devRequest: "JOINT iPaaS",
      },
      footer: {
        commercialTransAct: "Article of Specified Commercial Transaction Law",
        inquiry: "Inquiry",
        summary:
          "By integrating SaaS with eachother, make your daily operations more effeicient.",
        company: "Managing Company",
        termsOfService: "Terms of Service",
        supportServiceTerms: "SaaStainer Business Support Terms of Service",
        privacy: "Privacy Policy",
      },
      listEmployee: {
        email: "Email",
        firstName: "First Name",
        lastName: "Last Name",
        delete: "Delete",
        select: "Edit",
        unSelect: "Undo",
        confirmDelete: "Confirm deletion",
        confirmDeleteText: "Are you sure you want to delete this user?",
        description:
          "Increase the number of users that are allowed to use your applications!\nYou are able to increase the number of users allowed to access applications you have purchased or setup.\nAfter adding a user, an account registration email will be sent to the specified address. \nEmail addresses may only be used once on this site.",
        addHeader: "Add employee",
        listHeader: "Employee list",
        editHeader: "Edit employee",
        noEmployees: "Add some employees!",
        add: "Add",
        list: "List",
        edit: "Edit",
        cancel: "Cancel",
      },
      editProfile: {
        header: "Edit Account",
        description:
          "Edit account information.\nAfter changing password, a confirmation email will be delivered.",
      },
      appPurchased: {
        purchasedText: "Thank you for your purchase.",
        toAppList: "Back to apps",
      },
      inquiry: {
        inquiry: "Inquiry",
        summary:
          "Please feel free to let us know what you think about our apps, or anything else.",
      },
      oauth: {
        title: "OAuth Login",
        signup: "Signup",
      },
      signup: {
        title: "Signup",
        subTitle: "What is a Company ID?",
        subSubTitle:
          "After registering, you are able to register other users in your company to use the applications you purchase.",
        companyIdHelp: "A unique id for identifying your company.",
        login: "Login",
        aboutSaaStainer: " to learn more about SaaStainer.",
        registerSaaStainer: " to learn how to register as a SaaStainer.",
        inquiry: "Click here",
      },
      thanksSignup: {
        text: "Mail has been sent",
      },
      pageTitles: {
        purchasePage: "Purchase",
        appDetailsPage: "Details",
        appsPage: "SaaS x SaaS = Work Efficiency",
        employeesPage: "Employee Management",
        editProfilePage: "Edit Profile",
        actPage: "特定商取引法に基づく表記",
        termsPage: "「SaaStainer by JOINT」利用規約",
        supportServiceTermsPage: "SaaStainer業務支援サービス利用規約",
        privacyPage: "Privacy Policy",
      },
      alerts: {
        loggedIn: "Logged in",
        updated: "Updated",
        getDataError: "Unable to get data",
        loggedOut: "Logged out",
      },
      globalHeader: {
        login: "Login",
        signup: "Free Trial",
        menuitemProfile: "Edit Profile",
        menuitemSignout: "Logout",
        menuitemEmployees: "Employee Management",
        menuitemApps: "Apps",
        helpPage: "Help",
        searchBar: "Search Apps",
      },
      appDetail: {
        relatedApps: "Related apps for the same service",
        listNoResults: "No related apps for the same service",
      },
      appSearch: {
        appList: "Back to Apps",
        listResults: "Search results : ",
        listNoResults: "No apps match search conditions",
      },
      termsUpdate: {
        title: "Notice of Terms of Service Update",
        message1: "Thank you for your continued support of SaaStainer.",
        message2:
          "As of March 1st, SaaStainer has been renamed SaaStainer By JOINT as one of the products offered by Strategit's JOINT platform.",
        message3:
          "In line with this change, we have updated our Terms of Use. As a result of this change, we have updated our Terms of Use.",
        link: "View Terms of Service",
        dontShowAgain: "Don't show this message again",
        close: "Understood",
      },
    },
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language") || "ja",
  fallbackLng: "ja",
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
